import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChannelsService } from '@app/services/channels.service';
import { OperatorsService } from '@app/services/operators.service';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { IChannel } from 'libs/shared/src/lib/interfaces';

@Component({
  selector: 'blu-add-channel',
  templateUrl: './add-channel.component.html',
  styleUrls: ['./add-channel.component.scss'],
})
export class AddChannelComponent implements OnInit {
  channel?: IChannel;
  id?: string;
  number = {
    countryCode: '55',
    areaCode: '',
    number: '',
  };

  constructor(
    public route: ActivatedRoute,
    public sharedService: SharedService,
    public operatorListService: OperatorsService,
    public userService: UserService,
    public channelsService: ChannelsService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      this.channel = await this.initChannel();
      return;
    }
    this.id = id;
    let channel = await this.channelsService.getChannel(id);
    if (!channel) channel = await this.initChannel();
    this.number = {
      countryCode: channel.numbers[0].substring(0, 2),
      areaCode: channel.numbers[0].substring(2, 4),
      number: channel.numbers[0].substring(4),
    };
    this.channel = channel;

    const orgId = this.userService.organization()?.uid;
    if (orgId && !this.operatorListService.operators.length) {
      this.operatorListService.getOperators(orgId);
    }
  }

  private async initChannel() {
    const org = this.userService.organization();
    if (!org) throw new Error('No organization found');
    return {
      name: undefined,
      createdAt: new Date(),
      deletedAt: null,
      operators: [],
      numbers: [],
      organization: org.uid,
      startedWarmingUpAt: null,
      activeFlow: null,
    } as IChannel;
  }

  setName(tar: EventTarget | null): void {
    if (!this.channel) return;
    this.channel.name = (tar as HTMLInputElement).value;
  }

  setNumber(
    key: 'countryCode' | 'areaCode' | 'number',
    event: Event,
    maxlength: number,
  ): void {
    if (!this.channel) return;
    const tar = event.target as HTMLInputElement;
    const number = tar.value.replace(/\D/g, '').substring(0, maxlength);
    this.number[key] = number ? number : '';
    tar.value = this.number[key];
    if (
      !this.number.countryCode ||
      !this.number.areaCode ||
      !this.number.number
    ) {
      return;
    }
    const jointNumber = `${this.number.countryCode}${this.number.areaCode}${this.number.number}`;
    const jointNumberExtra9 = `${this.number.countryCode}${this.number.areaCode}9${this.number.number}`;
    this.channel.numbers = [jointNumber, jointNumberExtra9];
  }

  addOperatorToChannel(operatorId: string) {
    if (!this.channel?.operators) return;
    const index = this.channel.operators.indexOf(operatorId);
    if (index !== -1) {
      this.channel.operators.splice(index, 1);
    } else {
      this.channel?.operators.push(operatorId);
    }
  }

  async delete(): Promise<void> {
    if (!this.channel?.uid) return;
    await this.channelsService.deleteChannel(this.channel.uid);
    this.sharedService.closeModal(this.route);
  }

  async save(): Promise<void> {
    if (!this.channel) return;
    if (this.channel.uid) {
      await this.channelsService.updateChannel(this.channel.uid, this.channel);
    } else await this.channelsService.createChannel(this.channel);
    this.sharedService.closeModal(this.route);
  }
}
