<div
  class="card"
  [style.height]="sharedService.screenSize === 'l' ? 'auto' : '100%'"
  [style.width]="sharedService.screenSize === 'l' ? '480px' : '100%'"
>
  <!-- header -->
  <header class="header">
    <h2>Editar chave de disparo</h2>
    <kor-icon
      button
      icon="close"
      (click)="
        sharedService.closeModal(route);
        analyticsService.logEvent('admin_add-user-modal_close_click')
      "
    ></kor-icon>
  </header>
  <!-- body -->
  <div class="body">
    <kor-input
      label="Nome"
      [value]="name"
      (input)="setName($event)"
    ></kor-input>
    <div class="number">
      <kor-input
        no-clear
        label="País"
        maxlength="2"
        [value]="number.countryCode"
        (input)="setNumber('countryCode', $event, 2)"
        [attr.readonly]="id"
      ></kor-input>
      <kor-input
        no-clear
        label="DDD"
        maxlength="2"
        [value]="number.areaCode"
        (input)="setNumber('areaCode', $event, 2)"
        [attr.readonly]="id"
      ></kor-input>
      <kor-input
        no-clear
        label="Número"
        maxlength="8"
        [value]="number.number"
        (input)="setNumber('number', $event, 8)"
        [attr.readonly]="id"
      ></kor-input>
    </div>
    <!-- tags -->
    @if (userService.organization()?.userTags?.length) {
      <hr />
      <h2>Categorias</h2>
      <div class="tags">
        @for (tag of userService.organization()?.userTags; track tag) {
          <kor-tag
            icon="label"
            [label]="tag"
            class="button"
            [ngClass]="{ active: tags.includes(tag) }"
            (click)="
              toggleTag(tag);
              analyticsService.logEvent('admin_add-user-modal_tag_click')
            "
          ></kor-tag>
        }
      </div>
    }
    <!-- export contacts as csv -->
    @if (id) {
      <hr />
      <kor-menu-item
        [toggle]="false"
        label="Exportar contatos como CSV"
        icon="contacts"
        (click)="exportContactsToCsv()"
      ></kor-menu-item>
    }
  </div>
  <!-- footer -->
  <div class="footer">
    @if (id) {
      <kor-button
        color="tertiary"
        style="margin-right: auto"
        label="Deletar"
        (click)="
          deleteUser(); analyticsService.logEvent('admin_delete_user_click')
        "
      ></kor-button>
    }
    <kor-button
      label="Salvar"
      slot="footer"
      [disabled]="
        !name || !number.countryCode || !number.areaCode || !number.number
      "
      (click)="saveUser(); analyticsService.logEvent('admin_save_user_click')"
    ></kor-button>
  </div>
</div>
